
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cover-card{
  /* border: 1px solid red; */
  /* width: calc(100vh - 70px); */
  height: calc(100vh - 140px);
}

.cover-img{
  width: calc(100vh - 140px);
  height: calc(100vh - 140px);
}

.slide {
  opacity: 0;
  transition-duration: 1s ease-in-out;
  position:absolute
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1);
}

.team-img{
  width: calc(100vh - 140px - 3rem);
  height: calc(100vh - 140px - 3rem);
}

.project-card{
  width: calc(100vh - 140px - 7rem);
  height: calc(100vh - 140px - 7rem);
}

.right-arrow { 
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
	border-top: 10px solid transparent;
	border-left: 15px solid #555;
	border-bottom: 10px solid transparent;
}

.right-arrow-b{
  width:5px;
  height:20px;
  background: transparent;
}

.parent-right{
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.left-arrow-b{
  width:5px;
  height:20px;
  background: transparent;
}

.parent-left{
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  transform: rotate(180deg);
}

.sidebar{
  height: calc(100vh - 70px)
}

.home-arrow{
  width: 20px;
  height: 20px;
}