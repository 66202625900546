.first-rect{
    width: 4rem;
    height: 4px; 
    position: absolute;
    bottom: 0px;
    
    animation: slide 3s ;
    animation-iteration-count: 1

}

@keyframes slide {
    100%{
        transform: scale(1.6,1) translateY(18px) translateX(-8px);
    }
}

.second-rect{
    width: 4rem;
    height: 4px; 
    position: absolute;
    bottom: 0px;
   
    animation: slide1 3s;
    animation-iteration-count: 1


}

@keyframes slide1 {
    100%{
        transform: scale(2.4,1) translateY(38px) translateX(-12px);
    }
}

.third-rect{
    width: 4rem;
    height: 4px; 
    position: absolute;
    bottom: 0px;
    animation: slide3 3s;
    animation-iteration-count: 1

}

@keyframes slide3 {
    100%{
        transform: scale(3.3,1) translateY(58px) translateX(-14px);
    }
}